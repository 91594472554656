// src/App.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Paper,
  Alert,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme"; // Import your custom theme
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { motion } from "framer-motion"; // For animations

// Import your custom GIF (adjust path if needed)
import stavGif from "../../components/Stav.gif";

const App = () => {
  /***************************************************************************
   * State Variables
   ***************************************************************************/
  const [user, setUser] = useState(null);
  const auth = getAuth();

  const [message, setMessage] = useState("");
  const [summary, setSummary] = useState("");
  const [audioUrl, setAudioUrl] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  /***************************************************************************
   * Firebase Auth
   ***************************************************************************/
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, [auth]);

  /***************************************************************************
   * Handlers
   ***************************************************************************/
  const handleMessageChange = (e) => {
    const value = e.target.value;
    // Character limit check
    if (value.length > 1000) {
      setError("Message cannot exceed 1000 characters.");
      return;
    }
    setMessage(value);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!message.trim()) {
      setError("Message cannot be empty.");
      return;
    }

    setLoading(true);
    setError("");
    setSummary("");
    setAudioUrl("");

    try {
      // 1) Send message to your backend
      const response = await axios.post(
        "https://api.stavbot.com/api/interpret",
        {
          text: message,
          username: user?.displayName || "Anonymous",
        }
      );

      // 2) Extract AI summary
      const { summary: aiSummary } = response.data;
      setSummary(aiSummary);

      // 3) Generate audio via ElevenLabs
      const audioResponse = await fetch(
        "https://api.elevenlabs.io/v1/text-to-speech/M6Tg6dgaTEln2kszXt9c",
        {
          method: "POST",
          headers: {
            "xi-api-key": "b6380125681f6a1dfd020d10ed491a4d",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: aiSummary,
            voice_settings: {
              similarity_boost: 0.5,
              stability: 0.3,
              style: 0.25,
            },
          }),
        }
      );

      if (!audioResponse.ok) {
        throw new Error("Failed to generate audio.");
      }

      const audioBlob = await audioResponse.blob();
      const newAudioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(newAudioUrl);

      // Clear input after success
      setMessage("");
    } catch (err) {
      console.error("Error:", err);
      setError("An error occurred. Please try again.");
    } finally {
      // Reveal the UI again
      setLoading(false);
    }
  };

  // Optional clear function to reset states
  const clearAll = () => {
    setMessage("");
    setSummary("");
    setAudioUrl("");
    setError("");
  };

  /***************************************************************************
   * Render
   ***************************************************************************/

  // If loading, show a full-screen GIF (larger), hide the entire UI
  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            minHeight: "100vh",
            backgroundImage:
              'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://lastfm.freetls.fastly.net/i/u/ar0/8c796eed7cb466d75caafda6f9b788e3.jpg")',
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={stavGif}
            alt="Loading..."
            style={{ width: 200, height: 200 }}
          />
        </Box>
      </ThemeProvider>
    );
  }

  // Otherwise, show the main UI
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: "100vh",
          backgroundImage:
            'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://lastfm.freetls.fastly.net/i/u/ar0/8c796eed7cb466d75caafda6f9b788e3.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
        }}
      >
        <Container maxWidth="sm">
          <Paper
            elevation={6}
            sx={{
              padding: 4,
              backgroundColor: "rgba(255, 255, 255, 0.85)",
              borderRadius: 3,
            }}
          >
            <Typography variant="h4" align="center" gutterBottom>
              Ask Nick a Question
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              Nick will respond using the power of AI.
            </Typography>

            {/* Form */}
            <form onSubmit={handleSubmit}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Your Message"
                  placeholder="Ask Nick anything..."
                  value={message}
                  onChange={handleMessageChange}
                  variant="outlined"
                  inputProps={{ maxLength: 1000 }}
                  helperText={`${message.length}/1000 characters`}
                />
              </Box>

              {error && (
                <Box sx={{ mb: 2 }}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}

              <Box sx={{ textAlign: "center", mb: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{
                    paddingX: 4,
                    paddingY: 1.5,
                    fontSize: "1rem",
                    borderRadius: 2,
                    boxShadow: 3,
                    transition: "all 0.3s ease",
                    "&:hover": {
                      boxShadow: 6,
                      transform: "translateY(-2px)",
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </form>

            {/* Response Section */}
            {summary && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h5" gutterBottom align="center">
                    Nick's Response
                  </Typography>
                  <Typography variant="body1" align="center">
                    {summary}
                  </Typography>
                </Box>
              </motion.div>
            )}

            {/* Audio Section */}
            {audioUrl && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <Box sx={{ mt: 4, textAlign: "center" }}>
                  <Typography variant="h6" gutterBottom>
                    Listen to Nick's Response
                  </Typography>
                  <audio src={audioUrl} controls style={{ width: "100%" }} />
                </Box>
              </motion.div>
            )}

            {/* Clear Button */}
            {(summary || audioUrl || error) && (
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={clearAll}
                  sx={{
                    borderRadius: 2,
                    boxShadow: 1,
                    transition: "all 0.3s ease",
                    "&:hover": {
                      boxShadow: 3,
                      transform: "translateY(-2px)",
                    },
                  }}
                >
                  Clear and Ask Again
                </Button>
              </Box>
            )}
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default App;
