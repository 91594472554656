import React, { useState, useEffect } from "react";
import SoundBoard from "../../components/SoundBoard";
import {
  Box,
  Typography,
  Container,
  Paper,
  CircularProgress,
  Alert,
  IconButton,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import RefreshIcon from "@mui/icons-material/Refresh";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

// Define a custom theme
const getCustomTheme = (mode) =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: "#1976d2",
      },
      secondary: {
        main: "#dc004e",
      },
      background: {
        default: mode === "light" ? "#f5f5f5" : "#121212",
        paper: mode === "light" ? "#ffffff" : "#1e1e1e",
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      h3: {
        fontWeight: 600,
        marginBottom: "16px",
      },
      body1: {
        fontSize: "1rem",
      },
    },
  });

function Nick() {
  const [nickSounds, setNickSounds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [mode, setMode] = useState("light");

  useEffect(() => {
    const fetchNickSounds = async () => {
      const url = "https://server.stavbot.com";

      try {
        const response = await fetch(`${url}/getAudio/stav`);
        if (!response.ok) {
          throw new Error("Failed to fetch sounds.");
        }
        const data = await response.json();
        setNickSounds(data);
      } catch (err) {
        console.error(err);
        setError("Unable to load sounds. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchNickSounds();
  }, []);

  const refreshSounds = async () => {
    setLoading(true);
    setError("");
    try {
      const url = "https://server.stavbot.com";
      const response = await fetch(`${url}/getAudio/stav`);
      if (!response.ok) {
        throw new Error("Failed to fetch sounds.");
      }
      const data = await response.json();
      setNickSounds(data);
    } catch (err) {
      console.error(err);
      setError("Unable to refresh sounds. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  return (
    <ThemeProvider theme={getCustomTheme(mode)}>
      <Helmet>
        <title>Stavbot - Talk to Stav</title>
        <meta
          name="description"
          content="Welcome to Stavbot! Press buttons and listen to his humorous responses."
        />
        <meta
          name="keywords"
          content="Stavbot, Nick, talk, questions, humor, responses, login"
        />
      </Helmet>

      <Box
        sx={{
          minHeight: "100vh",
          // Use two backgrounds:
          //  1) a semi-transparent gradient (no-repeat, covers entire area)
          //  2) the actual image, repeated so it’s not stretched
          backgroundImage:
            mode === "light"
              ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), 
                 url("https://i.ticketweb.com/i/00/08/98/21/81/Original.jpg?v=1")`
              : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), 
                 url("https://i.ticketweb.com/i/00/08/98/21/81/Original.jpg?v=1")`,
          backgroundRepeat: "no-repeat, repeat",
          backgroundSize: "cover, auto",
          backgroundPosition: "center, 0 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: 2, md: 4 },
          paddingBottom: { xs: 6, md: 10 },
        }}
      >
        <Container maxWidth="md">
          <Paper
            elevation={6}
            sx={{
              padding: { xs: 2, sm: 3, md: 4 },
              backgroundColor: "rgba(255, 255, 255, 0.85)",
              borderRadius: 3,
              boxShadow: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: { xs: "100%", sm: "600px", md: "800px" },
              width: "100%",
              margin: "0 auto",
            }}
          >
            {/* Theme Toggle and Refresh Buttons */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mb: 2,
              }}
            >
              <IconButton
                onClick={toggleTheme}
                color="inherit"
                aria-label="Toggle Theme"
              >
                {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
              <IconButton
                onClick={refreshSounds}
                color="primary"
                aria-label="Refresh Sounds"
              >
                <RefreshIcon />
              </IconButton>
            </Box>

            {/* Page Title */}
            <Typography variant="h3" component="h1" gutterBottom>
              Talk to Adam
            </Typography>

            {/* Error Alert */}
            {error && (
              <Box sx={{ width: "100%", mb: 2 }}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}

            {/* Loading Indicator */}
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <>
                {/* SoundBoard Component */}
                {nickSounds.length > 0 ? (
                  <SoundBoard sounds={nickSounds} />
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    No sounds available.
                  </Typography>
                )}
              </>
            )}
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Nick;
